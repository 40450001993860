<template>
  <div
    :style="{ backgroundImage: backgroundUrl }"
    class="data-page md-scrollbar md-layout md-alignment-center-center"
    :class="{
      'data-page--jepa': isJEPA,
      'data-page--jep': isJEP,
      'app-jepa__background': isJEPA,
    }"
  >
    <img
      v-if="isGC"
      class="data-page__corner-img"
      src="../assets/images/logo_corner.png"
    />

    <img
      v-if="isLCM"
      class="data-page__corner-img logo-corner-lcm"
      src="../assets/images/logo-corner-lcm.svg"
    />

    <!-- Le logo et le titre -->

    <md-card
      v-if="!isLoading"
      class="meep-form data-page__form md-layout-item md-xsmall-size-90 md-small-size-80 md-xlarge-size-50"
      md-with-hover
    >
      <md-card-media class="data-page__form-media">
        <md-icon v-if="logo" class="data-page__logo" v-html="logo" />

        <MeepIconLogoMeep v-else class="data-page__logo" />
      </md-card-media>

      <md-card-header>
        <div class="md-title title">{{ $t("data.banner") }}</div>
      </md-card-header>

      <md-card-content v-if="isGC">
        <h3 class="personal-data__title">{{ $t("data.first-title") }}</h3>

        <p>
          Expert SA est une agence web basée à Paris. Découvrez notre agence sur
          notre site web
          <a href="https://www.expertsa.fr/" target="_blank">
            https://www.expertsa.fr/
          </a>
          .
        </p>

        <p>
          Le responsable du traitement des données est la société Expert SA,
          située au 17 rue du Niger, 75012 Paris.
        </p>

        <p>
          Pour toute question relative à vos données personnelles, veuillez nous
          contacter à l’adresse
          <a href="mailto:info@expertsa.fr">info@expertsa.fr</a>
          .
        </p>

        <h3 class="personal-data__title">{{ $t("data.second-title") }}</h3>

        <h4 class="personal-data__sub-title">Motifs de collecte</h4>

        <p>
          Nous collectons les données strictement nécessaires au fonctionnement
          du site web
          <a href="https://groupeconseils.meep-appli.fr ">
            groupeconseils.meep-appli.fr
          </a>
          ainsi qu’à l’application associée Groupe Conseils. Les données ne sont
          pas conservées au-delà du temps nécessaire dans le cadre de notre
          collaboration.
        </p>

        <p>Les informations recueillies peuvent être utilisées pour :</p>

        <ul>
          <li>Vous permettre de vous connecter à votre compte;</li>

          <li>
            Répondre à vos demandes d’information et le cas échéant vous envoyer
            des informations complémentaires.
          </li>
        </ul>

        <p>
          Les informations recueillies par l’intermédiaire de notre site sont
          réservées à l’usage de la société Groupe Conseils. Nous nous engageons
          à respecter leur confidentialité.
        </p>

        <h4 class="personal-data__sub-title">Formulaire d’inscription</h4>

        <p>
          En créant un compte sur le site, vous nous donnez expressément votre
          consentement pour que vos données personnelles soient traitées en vue
          de vous apporter une réponse. Dans le cas d’une question d’ordre
          commerciale (demande d’information sur un produit ou service, demande
          de devis) vous pourrez recevoir des informations complémentaires sur
          nos services par email.
        </p>

        <p>Les données collectées sont les suivantes :</p>

        <ul>
          <li>Civilité ;</li>

          <li>Nom ;</li>

          <li>Prénom ;</li>

          <li>Société ;</li>

          <li>Site web ;</li>

          <li>Téléphone ;</li>

          <li>Adresse email ;</li>

          <li>
            Autres informations librement divulguées dans le champ « message ».
          </li>
        </ul>

        <p>
          En vous inscrivant, vous serez susceptible de recevoir des newsletters
          commerciales et d’information. Vous pourrez à tout moment mettre fin à
          l’envoi d’emails en cliquant sur le lien de désabonnement présent à la
          fin de chaque newsletter ou email.
        </p>

        <h4 class="personal-data__sub-title">Cookies et analyse d’audience</h4>

        <p>
          Le site utilise des cookies et des outils d’analyse de trafic pour
          réaliser des statistiques et améliorer la pertinence des contenus
          proposés :
        </p>

        <ul>
          <li>
            des cookies de session pour faciliter votre navigation sur le Site
          </li>

          <li>des cookies permettant de mesurer l’audience du site</li>

          <li>
            un outil permettant de traiter les soumissions des formulaires et
            l’envoi de messages automatisés (abonnement au blog, envoi
            d’informations en lien avec une demande déposées via le formulaire
            de contact).
          </li>
        </ul>

        <h4 class="personal-data__sub-title">
          Contenu embarqué depuis d’autres sites
        </h4>

        <p>
          Les articles de ce site peuvent inclure des contenus intégrés (par
          exemple des vidéos, images, articles…). Le contenu intégré depuis
          d’autres sites se comporte de la même manière que si le visiteur se
          rendait sur cet autre site.
        </p>

        <p>
          Ces sites web pourraient collecter des données sur vous, utiliser des
          cookies, embarquer des outils de suivis tiers, suivre vos interactions
          avec ces contenus embarqués si vous disposez d’un compte connecté sur
          leur site web.
        </p>

        <h3 class="personal-data__title">{{ $t("data.third-title") }}</h3>

        <h4 class="personal-data__sub-title">
          Les droits que vous avez sur vos données
        </h4>

        <p>
          En vertu du droit applicable, vous disposez d’un droit d’accès, de
          rectification ou d’effacement, de limitation ou d’opposition au
          traitement ainsi qu’un droit de portabilité de vos données
          personnelles.
        </p>

        <p>Vous disposez notamment du droit :</p>

        <ul>
          <li>
            d’accéder à vos données personnelles, notamment pour vérifier leur
            exactitude et leur exhaustivité ;
          </li>

          <li>de demander une rectification de vos données ;</li>

          <li>
            de vous opposer ou de demander une limitation du traitement de vos
            données personnelles ;
          </li>

          <li>
            de recevoir vos données personnelles sous un format standard et
            structuré.
          </li>
        </ul>

        <p>
          Nous mettons tout en œuvre pour que vos données personnelles soient
          protégées en tout temps, contre toute destruction accidentelle ou
          illicite, ou contre la perte accidentelle, l’altération, la diffusion
          ou l’accès non autorisés, ainsi que contre toute autre forme de
          traitement illicite.
        </p>

        <p>
          Pour exercer vos droits ou obtenir davantage d’information sur le
          traitement de vos données personnelles, veuillez nous contacter à
          l’adresse
          <a href="mailto:info@expertsa.fr">info@expertsa.fr</a>
          .
        </p>
      </md-card-content>

      <md-card-content v-else>
        <h3 class="personal-data__title">
          {{ $t("personal-data.who-are-we") }}
        </h3>

        <p>
          Expert SA est une agence web basée à Paris. Découvrez notre agence sur
          notre site web
          <a href="https://www.expertsa.fr/" target="_blank">
            https://www.expertsa.fr/
          </a>
          .
        </p>

        <p>
          Le responsable du traitement des données est la société Expert SA,
          située au 17 rue du Niger, 75012 Paris.
        </p>

        <p>
          Pour toute question relative à vos données personnelles, veuillez nous
          contacter à l’adresse
          <a href="mailto:info@expertsa.fr">info@expertsa.fr</a>
          .
        </p>

        <h3 class="personal-data__title">
          {{ $t("personal-data.use-data-collected") }}
        </h3>

        <h4 class="personal-data__sub-title">Motifs de collecte</h4>

        <p>
          Nous collectons les données strictement nécessaires au fonctionnement
          du site web v2.meep-appli.fr ainsi qu’à l’application associée MEEP :
          Mon expert en poche. Les données ne sont pas conservées au-delà du
          temps nécessaire dans le cadre de notre collaboration.
        </p>

        <p>Les informations recueillies peuvent être utilisées pour :</p>

        <ul>
          <li>Vous permettre de vous connecter à votre compte MEEP;</li>

          <li>
            Répondre à vos demandes d’information et le cas échéant vous envoyer
            des informations complémentaires.
          </li>
        </ul>

        <p>
          Les informations recueillies par l’intermédiaire de notre site sont
          réservées à l’usage de la société Expert Sa. Nous nous engageons à
          respecter leur confidentialité.
        </p>

        <h4 class="personal-data__sub-title">Formulaire d’inscription</h4>

        <p>
          En créant un compte sur le site, vous nous donnez expressément votre
          consentement pour que vos données personnelles soient traitées en vue
          de vous apporter une réponse. Dans le cas d’une question d’ordre
          commerciale (demande d’information sur un produit ou service, demande
          de devis) vous pourrez recevoir des informations complémentaires sur
          nos services par email.
        </p>

        <p>Les données collectées sont les suivantes :</p>

        <ul>
          <li>Civilité ;</li>

          <li>Nom ;</li>

          <li>Prénom ;</li>

          <li>Société ;</li>

          <li>Site web ;</li>

          <li>Téléphone ;</li>

          <li>Adresse email ;</li>

          <li>
            Autres informations librement divulguées dans le champ « message ».
          </li>
        </ul>

        <p>
          En vous inscrivant, vous serez susceptible de recevoir des newsletters
          commerciales et d’information. Vous pourrez à tout moment mettre fin à
          l’envoi d’emails en cliquant sur le lien de désabonnement présent à la
          fin de chaque newsletter ou email.
        </p>

        <h4 class="personal-data__sub-title">Cookies et analyse d’audience</h4>

        <p>
          Le site utilise des cookies et des outils d’analyse de trafic pour
          réaliser des statistiques et améliorer la pertinence des contenus
          proposés :
        </p>

        <ul>
          <li>
            des cookies de session pour faciliter votre navigation sur le Site
          </li>

          <li>des cookies permettant de mesurer l’audience du site</li>

          <li>
            un outil permettant de traiter les soumissions des formulaires et
            l’envoi de messages automatisés (abonnement au blog, envoi
            d’informations en lien avec une demande déposées via le formulaire
            de contact).
          </li>
        </ul>

        <h4 class="personal-data__sub-title">
          Contenu embarqué depuis d’autres sites
        </h4>

        <p>
          Les articles de ce site peuvent inclure des contenus intégrés (par
          exemple des vidéos, images, articles…). Le contenu intégré depuis
          d’autres sites se comporte de la même manière que si le visiteur se
          rendait sur cet autre site.
        </p>

        <p>
          Ces sites web pourraient collecter des données sur vous, utiliser des
          cookies, embarquer des outils de suivis tiers, suivre vos interactions
          avec ces contenus embarqués si vous disposez d’un compte connecté sur
          leur site web.
        </p>

        <h3 class="personal-data__title">
          {{ $t("personal-data.use-and-transmission-data") }}
        </h3>

        <p>
          En vertu du droit applicable, vous disposez d’un droit d’accès, de
          rectification ou d’effacement, de limitation ou d’opposition au
          traitement ainsi qu’un droit de portabilité de vos données
          personnelles.
        </p>

        <p>Vous disposez notamment du droit :</p>

        <ul>
          <li>
            d’accéder à vos données personnelles, notamment pour vérifier leur
            exactitude et leur exhaustivité ;
          </li>

          <li>de demander une rectification de vos données ;</li>

          <li>
            de vous opposer ou de demander une limitation du traitement de vos
            données personnelles ;
          </li>

          <li>
            de recevoir vos données personnelles sous un format standard et
            structuré.
          </li>
        </ul>

        <p>
          Nous mettons tout en œuvre pour que vos données personnelles soient
          protégées en tout temps, contre toute destruction accidentelle ou
          illicite, ou contre la perte accidentelle, l’altération, la diffusion
          ou l’accès non autorisés, ainsi que contre toute autre forme de
          traitement illicite.
        </p>

        <p>
          Pour exercer vos droits ou obtenir davantage d’information sur le
          traitement de vos données personnelles, veuillez nous contacter à
          l’adresse
          <a href="mailto:info@expertsa.fr">info@expertsa.fr</a>
          .
        </p>
      </md-card-content>
    </md-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import HasBackMixin from "@/mixins/HasBackMixin";
export default {
  name: "PersonalData",
  components: {},
  mixins: [HasBackMixin],
  computed: {
    ...mapGetters([
      "organizationTheme",
      "logo",
      "organizationId",
      "backgroundUrl",
      "isGC",
      "isLCM",
      "isJEPA",
      "isJEP",
    ]),
  },
  mounted() {
    this.$scrollTo(".personal-data__card");
  },
};
</script>

<style lang="scss" scoped>
@import "../styles/_variable.scss";

.data-page {
  height: 100vh;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: auto;

  .data-page__logo {
    max-width: 275px;
    width: 100%;
    height: auto;

    /deep/ svg {
      width: 100%;
      height: auto;
    }
  }

  &.data-page--jepa,
  &.data-page--jep {
    .data-page__form-media {
      height: auto;

      .data-page__logo {
        margin-top: 23px;
        margin-bottom: 23px;
        width: 235px;
        height: auto;
      }
    }
  }

  &__corner-img {
    position: absolute;
    transform: rotate(90deg);
    top: 0;
    left: 0;
    width: 20%;

    &.logo-corner-lcm {
      top: 32px;
      width: 20em;
      transform: rotate(0) translate(-1em);
    }
  }

  &__form.md-card.meep-form {
    margin: 100px 0;
    cursor: default;
    padding: 20px 50px;
    border-radius: 27px;
    box-shadow: none;
    max-width: 824px;

    &.md-with-hover:hover {
      box-shadow: none;
      cursor: default;
    }

    .md-card {
      &-header {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 25px;

        .md-title {
          font-size: toRem(18);
          font-family: var(--font-bold);
          @include for-lg {
            font-size: toRem(22);
          }
        }

        .md-subhead {
          opacity: 1;
          font-size: 17px;
          font-family: var(--font-x);
        }
      }

      &-actions,
      &-content {
        padding: 0;
        flex-wrap: wrap;
        flex-direction: column;
      }
      &-actions {
        margin: 40px 0 50px;
        .md-button {
          margin-left: 10px;
          min-width: unset;
          width: fit-content;
        }
      }
    }

    .meep-input {
      flex: 0 0 100%;
    }
  }

  &__form-media {
    width: 100%;
    height: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .signup-button {
    color: var(--text-primary) !important;
  }
  .signin-button {
    min-width: unset;
    min-height: unset;
    height: 36px;
    @include for-lg {
      height: 43px;
    }
  }
  .loading-card {
    border-radius: 27px;
    height: 500px;
    justify-content: center;
    align-items: center;
    display: flex;
    box-shadow: 2px 2px 5px 0 rgba(0, 0, 0, 0.2);

    .md-progress-spinner-draw {
      width: 160px !important;
      height: 160px !important;
    }

    &__message {
      margin-top: 30px;
      font-size: 22px;
      font-family: var(--font-extrabold);
    }
  }
}

.personal-data {
  &__card {
    border-radius: 10px;
    padding: 50px 40px;

    .md-card-content {
      padding: 0;
      font-size: 17px;
    }
  }

  &__title {
    color: var(--bg-primary);
    font-size: 29px;
    margin: 25px 0;
    display: block;
  }
  &__sub-title {
    margin-top: 20px;
  }

  ul {
    list-style-type: none;

    li {
      text-indent: -5px;

      &::before {
        content: "-";
        margin-right: 0.5rem;
        text-indent: -5px;
      }
    }
  }
}
</style>

